import { SpaceBetween, TextContent } from '@amzn/awsui-components-react';
import React from 'react';
import { SolutionDefinition } from '../../../interfaces/solution.interface';

interface solutionDetailsProps {
    solution: SolutionDefinition;
}

const SolutionDetails: React.FC<solutionDetailsProps> = ({ solution }) => {
    return (
        <SpaceBetween size="l">
            <div>
                <TextContent>
                    <p style={{ whiteSpace: 'pre-line' }}>
                        {solution.description}
                    </p>
                </TextContent>
            </div>
        </SpaceBetween>
    );
};

export default SolutionDetails;
