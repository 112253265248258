import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Header,
    Input,
    Link,
    Multiselect,
    Pagination,
    PaginationProps,
    SpaceBetween,
    Table,
    TableProps,
} from '@amzn/awsui-components-react';
import { checkIfAdmin } from '../../../util/AuthService';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
    AWS_PROGRAMS,
    CUSTOMER_SEGMENT,
    GEO,
    SUB_SEGMENTS,
    VERTICALS,
    WORKLOADS,
} from '../../../constants/Constants';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { useFetchAllPartnersQuery } from '../../../store';
import { PartnerDefinition } from '../../../interfaces/partner.interface';
import { mapArrayToOptions, mapOptionsToArray } from '../../../util/MapOptions';

const SEARCHABLE_FIELDS = ['name'];

const createTableSortLabelFn = (
    column: TableProps.ColumnDefinition<unknown>
) => {
    if (
        !column.sortingField &&
        !column.sortingComparator &&
        !column.ariaLabel
    ) {
        return;
    }
    return ({ sorted, descending }) => {
        return `${column.header}, ${
            sorted
                ? `sorted ${descending ? 'descending' : 'ascending'}`
                : 'not sorted'
        }.`;
    };
};
const matchesSegment = (
    item: PartnerDefinition,
    selectedSegments: OptionDefinition[]
): boolean => {
    if (item.segments) {
        let arrayEmpty = selectedSegments.length < 1;
        let segments = selectedSegments.map((a) => a.label);
        let elementInArray = item.segments.some((element) =>
            segments.includes(element)
        );
        return arrayEmpty || elementInArray;
    } else return true;
};

const matchesWorkload = (
    item: PartnerDefinition,
    selectedWorkloads: OptionDefinition[]
): boolean => {
    if (item.workloads) {
        let arrayEmpty = selectedWorkloads.length < 1;
        let workloads = selectedWorkloads.map((a) => a.label);
        let elementInArray = item.workloads.some((element) =>
            workloads.includes(element)
        );
        return arrayEmpty || elementInArray;
    } else return true;
};

const matchesVertical = (
    item: PartnerDefinition,
    selectedVerticals: OptionDefinition[]
): boolean => {
    if (item.verticals) {
        let arrayEmpty = selectedVerticals.length < 1;
        let verticals = selectedVerticals.map((a) => a.label);
        let elementInArray = item.verticals.some((element) =>
            verticals.includes(element)
        );
        return arrayEmpty || elementInArray;
    } else return true;
};

const matchesProgram = (
    item: PartnerDefinition,
    selectedPrograms: OptionDefinition[]
): boolean => {
    if (item.programs) {
        let arrayEmpty = selectedPrograms.length < 1;
        let programs = selectedPrograms.map((a) => a.label);
        let elementInArray = item.programs.some((element) =>
            programs.includes(element)
        );
        return arrayEmpty || elementInArray;
    } else return true;
};

const Partners: React.FC = () => {
    const navigate = useNavigate();

    const { data: partners, isLoading: isLoadingPartners } =
        useFetchAllPartnersQuery();

    const [isAdmin, setIsAdmin] = useState(false);

    // const [segmentOption, setSegmentOption] = useState<OptionDefinition[]>([]);
    // const [subSegmentOption, setSubSegmentOption] = useState<OptionDefinition[]>([]);
    // const [geoOption, setGeoOption] = useState<OptionDefinition[]>([]);
    // const [workloadOption, setWorkloadOption] = useState<OptionDefinition[]>([]);
    // const [verticalOption, setVerticalOption] = useState<OptionDefinition[]>([]);
    // const [programOption, setProgramOption] = useState<OptionDefinition[]>([]);
    // const [sca, setSca] = useState(false);

    const [searchParams] = useSearchParams();
    const geoValueUrl = searchParams.get('geo');
    const geoValue = geoValueUrl ? geoValueUrl.split(',') : [];
    const segmentValueUrl = searchParams.get('segment');
    const segmentValue = segmentValueUrl ? segmentValueUrl.split(',') : [];
    const subSegmentValueUrl = searchParams.get('subsegment');
    const subSegmentValue = subSegmentValueUrl
        ? subSegmentValueUrl.split(',')
        : [];
    const workloadValueUrl = searchParams.get('workload');
    const workloadValue = workloadValueUrl ? workloadValueUrl.split(',') : [];
    const verticalValueUrl = searchParams.get('vertical');
    const verticalValue = verticalValueUrl ? verticalValueUrl.split(',') : [];
    const programValueUrl = searchParams.get('program');
    const programValue = programValueUrl ? programValueUrl.split(',') : [];

    const handleFilterChange = (options: OptionDefinition[], param: string) => {
        const filter = mapOptionsToArray(options);
        if (filter.length > 0) {
            searchParams.set(param, filter.join(','));
        } else {
            searchParams.delete(param);
        }
        navigate(`${location.pathname}?${searchParams.toString()}`);
    };

    const createLabelFunction = (columnName) => {
        return ({ sorted, descending }) => {
            const sortState = sorted
                ? `sorted ${descending ? 'descending' : 'ascending'}`
                : 'not sorted';
            return `${columnName}, ${sortState}.`;
        };
    };

    const columns = [
        {
            id: 'name',
            header: 'Partner Name',
            cell: (item) =>
                (
                    <Link onFollow={(e) => openPartnerDetails(e, item)}>
                        {item.name}
                    </Link>
                ) || '-',
            sortingField: 'name',
            ariaLabel: createLabelFunction('name'),
        },
        {
            id: 'geos',
            header: 'Geos',
            cell: (item) =>
                item.geos
                    ? item.geos.length > 0
                        ? item.geos.join(' | ')
                        : '-'
                    : '-',
        },
        {
            id: 'segments',
            header: 'Segments',
            cell: (item) =>
                item.segments
                    ? item.segments.length > 0
                        ? item.segments.join(' | ')
                        : '-'
                    : '-',
            minWidth: 175,
        },
        {
            id: 'subSegements',
            header: 'Sub Segments',
            cell: (item) =>
                item.subSegments
                    ? item.subSegments.length > 0
                        ? item.subSegments.join(' | ')
                        : '-'
                    : '-',
        },
        {
            id: 'workloads',
            header: 'Workloads',
            cell: (item) =>
                item.workloads
                    ? item.workloads.length > 0
                        ? item.workloads.join(' | ')
                        : '-'
                    : '-',
        },
        {
            id: 'verticals',
            header: 'Verticals',
            cell: (item) =>
                item.verticals
                    ? item.verticals.length > 0
                        ? item.verticals.join(' | ')
                        : '-'
                    : '-',
        },
        {
            id: 'programs',
            header: 'AWS Partner Programs',
            cell: (item) =>
                item.programs
                    ? item.programs.length > 0
                        ? item.programs.join(' | ')
                        : '-'
                    : '-',
        },
    ];

    const columnDefinitions = columns.map((column) => ({
        ...column,
        ariaLabel: createTableSortLabelFn(column),
    }));

    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        filterProps,
        paginationProps,
    } = useCollection(partners || [], {
        filtering: {
            empty: <TableEmptyState resourceName="Partner" />,
            noMatch: (
                <TableNoMatchState
                    onClearFilter={() => actions.setFiltering('')}
                />
            ),
            filteringFunction: (
                item: PartnerDefinition,
                filteringText: string
            ) => {
                if (
                    !matchesSegment(
                        item,
                        mapArrayToOptions(segmentValue, CUSTOMER_SEGMENT)
                    )
                ) {
                    return false;
                }
                if (
                    !matchesWorkload(
                        item,
                        mapArrayToOptions(workloadValue, WORKLOADS)
                    )
                ) {
                    return false;
                }
                if (
                    !matchesVertical(
                        item,
                        mapArrayToOptions(verticalValue, VERTICALS)
                    )
                ) {
                    return false;
                }
                if (
                    !matchesProgram(
                        item,
                        mapArrayToOptions(programValue, AWS_PROGRAMS)
                    )
                ) {
                    return false;
                }

                const filterTextLowerCase = filteringText.toLowerCase();

                return SEARCHABLE_FIELDS.map((key) => item[key]).some(
                    (value) =>
                        typeof value === 'string' &&
                        value.toLowerCase().indexOf(filterTextLowerCase) > -1
                );
            },
        },
        pagination: { pageSize: 30 },
        sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
        selection: {},
    });

    useEffect(() => {
        setIsAdmin(checkIfAdmin());
    }, []);

    const openPartnerDetails = (e, item) => {
        e.preventDefault();
        const path = `/partners/${item.name}`;
        navigate(path);
    };

    const loading = isLoadingPartners;

    return (
        <Table
            {...collectionProps}
            columnDefinitions={columns}
            wrapLines={true}
            items={items}
            loading={loading}
            stickyHeader={true}
            loadingText="Loading partners"
            filter={
                <div className="input-container">
                    <div className="input-filter">
                        <Input
                            type="search"
                            placeholder="Find Partner"
                            value={filterProps.filteringText}
                            onChange={(e) =>
                                actions.setFiltering(e.detail.value)
                            }
                            disabled={loading}
                        />
                    </div>
                    <div className="select-filter">
                        <Multiselect
                            disabled={loading}
                            hideTokens
                            placeholder="Geo Filter"
                            selectedOptions={mapArrayToOptions(geoValue, GEO)}
                            options={GEO}
                            onChange={(event) => {
                                handleFilterChange(
                                    [...event.detail.selectedOptions],
                                    'geo'
                                );
                            }}
                        />
                    </div>
                    <div className="select-filter">
                        <Multiselect
                            disabled={loading}
                            hideTokens
                            placeholder="Segment Filter"
                            selectedOptions={mapArrayToOptions(
                                segmentValue,
                                CUSTOMER_SEGMENT
                            )}
                            options={CUSTOMER_SEGMENT}
                            onChange={(event) => {
                                handleFilterChange(
                                    [...event.detail.selectedOptions],
                                    'segment'
                                );
                            }}
                        />
                    </div>
                    <div className="select-filter">
                        <Multiselect
                            disabled={loading}
                            hideTokens
                            placeholder="Sub Segment Filter"
                            selectedOptions={mapArrayToOptions(
                                subSegmentValue,
                                SUB_SEGMENTS
                            )}
                            options={SUB_SEGMENTS}
                            onChange={(event) => {
                                handleFilterChange(
                                    [...event.detail.selectedOptions],
                                    'subsegment'
                                );
                            }}
                        />
                    </div>
                    <div className="select-filter">
                        <Multiselect
                            disabled={loading}
                            hideTokens
                            placeholder="Workload Filter"
                            selectedOptions={mapArrayToOptions(
                                workloadValue,
                                WORKLOADS
                            )}
                            options={WORKLOADS}
                            onChange={(event) => {
                                handleFilterChange(
                                    [...event.detail.selectedOptions],
                                    'workload'
                                );
                            }}
                        />
                    </div>
                    <div className="select-filter">
                        <Multiselect
                            disabled={loading}
                            hideTokens
                            placeholder="Vertical Filter"
                            selectedOptions={mapArrayToOptions(
                                verticalValue,
                                VERTICALS
                            )}
                            options={VERTICALS}
                            onChange={(event) => {
                                handleFilterChange(
                                    [...event.detail.selectedOptions],
                                    'vertical'
                                );
                            }}
                        />
                    </div>
                    <div className="select-filter">
                        <Multiselect
                            disabled={loading}
                            hideTokens
                            placeholder="Program Filters"
                            selectedOptions={mapArrayToOptions(
                                programValue,
                                AWS_PROGRAMS
                            )}
                            options={AWS_PROGRAMS}
                            onChange={(event) => {
                                handleFilterChange(
                                    [...event.detail.selectedOptions],
                                    'program'
                                );
                            }}
                        />
                    </div>
                    <div aria-live="polite">
                        {(filterProps.filteringText ||
                            segmentValue ||
                            workloadValue ||
                            workloadValue ||
                            programValue) && (
                            <span className="filtering-results">
                                {getTextFilterCounterText(filteredItemsCount)}
                            </span>
                        )}
                    </div>
                </div>
            }
            header={<TableHeader isAdmin={isAdmin} />}
            pagination={
                <Pagination
                    {...paginationProps}
                    ariaLabels={paginationAriaLabels(
                        paginationProps.pagesCount
                    )}
                    disabled={loading}
                />
            }
        />
    );
};

interface TableHeader {
    isAdmin: boolean;
}

const TableHeader: React.FC<TableHeader> = (props) => {
    const navigate = useNavigate();
    const { isAdmin } = props;
    const addPartnerButton = isAdmin ? (
        <Button variant="primary" onClick={(e) => addPartner(e)}>
            Add partner
        </Button>
    ) : null;
    const addPartner = (e) => {
        e.preventDefault();
        navigate('/partners/add');
    };
    return (
        <div>
            <Header variant="awsui-h1-sticky" actions={addPartnerButton}>
                Partners
            </Header>
        </div>
    );
};

const TableEmptyState = ({ resourceName }) => (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No {resourceName.toLowerCase()}s</b>
                <Box variant="p" color="inherit">
                    No {resourceName.toLowerCase()}s.
                </Box>
            </div>
            {/*<Button>Create {resourceName.toLowerCase()}</Button>*/}
        </SpaceBetween>
    </Box>
);

const TableNoMatchState = (props) => (
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
            <div>
                <b>No matches</b>
                <Box variant="p" color="inherit">
                    We can't find a match.
                </Box>
            </div>
            <Button onClick={props.onClearFilter}>Clear filter</Button>
        </SpaceBetween>
    </Box>
);
const getTextFilterCounterText = (count: number | undefined) =>
    `${count} ${count === 1 ? 'match' : 'matches'}`;

const paginationAriaLabels: (totalPages?: number) => PaginationProps.Labels = (
    totalPages
) => ({
    nextPageLabel: 'Next page',
    previousPageLabel: 'Previous page',
    pageLabel: (pageNumber) =>
        `Page ${pageNumber} of ${totalPages || 'all pages'}`,
});
export default Partners;
