import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { FormOptionDefinition } from '../constants/Constants';

export const mapOptionsToArray = (options: OptionDefinition[]): string[] => {
    if (!options) {
        return [];
    }
    const data: string[] = [];
    options.map((option: OptionDefinition) => {
        if (option.label) {
            data.push(option.label);
        }
    });
    return data;
};

export const mapArrayToOptions = <T extends FormOptionDefinition>(
    array: string[],
    options: T[]
): OptionDefinition[] => {
    if (!array) {
        return [];
    }
    const data: OptionDefinition[] = [];
    array.map((item: string) => {
        options.find((option: T) => {
            if (option.label === item) {
                data.push(option);
            }
        });
    });
    return data;
};

export const mapFilterToOption = (
    filter: string | null,
    options: OptionDefinition[]
): OptionDefinition | null => {
    const defaultOption = options.find(
        (option: OptionDefinition) => option.value === ''
    );
    const option = options.find(
        (option: OptionDefinition) => option.value === filter
    );
    if (option) {
        return option;
    } else {
        return defaultOption ? defaultOption : null;
    }
};

export const mapOptionToFilter = (option: OptionDefinition | null): string => {
    if (!option || !option.value) {
        return '';
    }
    return option.value;
};

export const alphabetizeOptions = (
    options: OptionDefinition[]
): OptionDefinition[] => {
    return options.sort((a, b) => {
        if (a.label && b.label) {
            return a.label.localeCompare(b.label);
        }
        return 0;
    });
};
