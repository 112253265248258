import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    Form,
    Header,
    SpaceBetween,
} from '@amzn/awsui-components-react';

import { checkIfAdmin } from '../../../util/AuthService';
import PartnerDetailsForm, {
    PartnerDetailsFormProps,
} from './PartnerDetailsForm';
import OptionalPartnerDetailsForm, {
    OptionalPartnerDetailsFormProps,
} from './OptionalPartnerDetailsForm';
import {
    setNotification,
    useAddPartnerMutation,
    useEditPartnerMutation,
    useFetchPartnerQuery,
} from '../../../store';
import { skipToken } from '@reduxjs/toolkit/query';
import { PartnerDefinition } from '../../../interfaces/partner.interface';
import { useDispatch } from 'react-redux';
import { notificationTypeEnum } from '../../../constants/Constants';

interface PartnerFormProps {
    editMode: boolean;
}

const PartnerForm: React.FC<PartnerFormProps> = ({ editMode }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    let id: string | null = null;
    if (editMode) {
        const pathName = window.location.pathname;
        id = pathName.substring(pathName.lastIndexOf('/') + 1);
    }
    const { data: partner } = useFetchPartnerQuery(id ? id : skipToken);
    const [addPartner, addPartnerResult] = useAddPartnerMutation();
    const [editPartner, editPartnerResult] = useEditPartnerMutation();

    const [name, setName] = useState('');
    const [discoveryLink, setDiscoveryLink] = useState('');
    const [segments, setSegments] = useState<string[]>([]);
    const [verticals, setVerticals] = useState<string[]>([]);
    const [workloads, setWorkloads] = useState<string[]>([]);
    const [programs, setPrograms] = useState<string[]>([]);
    const [subSegments, setSubSegments] = useState<string[]>([]);
    const [geos, setGeos] = useState<string[]>([]);

    const [nameError, setNameError] = useState('');
    const [linkError, setLinkError] = useState('');
    const [segmentError, setSegmentError] = useState('');
    const [verticalError, setVerticalError] = useState('');
    const [workloadsError, setWorkloadsError] = useState('');
    const [partnerProgramsError, setPartnerProgramsError] = useState('');
    const [subSegmentsError, setSubSegmentsError] = useState('');
    const [geosError, setGeosError] = useState('');

    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        if (!checkIfAdmin()) {
            navigate('/partners');
        }
    }, []);

    useEffect(() => {
        if (editMode && partner) {
            setName(partner.name);
            setDiscoveryLink(partner.discoveryLink);
            setSegments(partner.segments);
            setVerticals(partner.verticals);
            setWorkloads(partner.workloads);
            setPrograms(partner.programs);
            setSubSegments(partner.subSegments);
            setGeos(partner.geos);
        }
    }, [partner]);

    const partnerDetailsFormProps: PartnerDetailsFormProps = {
        name,
        setName,

        discoveryLink,
        setDiscoveryLink,
        nameError,
        setNameError,

        linkError,
        setLinkError,

        editMode,
    };

    const additionalPartnerDetailsFormProps: OptionalPartnerDetailsFormProps = {
        segments,
        setSegments,
        verticals,
        setVerticals,
        workloads,
        setWorkloads,
        programs,
        setPrograms,
        subSegments,
        setSubSegments,
        geos,
        setGeos,
        segmentError,
        setSegmentError,
        verticalError,
        setVerticalError,
        workloadsError,
        setWorkloadsError,
        partnerProgramsError,
        setPartnerProgramsError,
        subSegmentsError,
        setSubSegmentsError,
        geosError,
        setGeosError,
    };

    const partnerForm: PartnerDefinition = {
        name,
        discoveryLink,

        segments: segments || [],
        verticals: verticals || [],
        workloads: workloads || [],
        programs: programs || [],
        subSegments: subSegments || [],
        geos: geos || [],
    };
    const onSubmit = (event): void => {
        event.preventDefault();
        setSubmitLoading(true);
        formValidation();
        if (formValidation()) {
            setSubmitLoading(false);
            return;
        }
        if (!editMode) {
            createNewPartner();
        } else {
            if (partner) {
                partnerForm.createdAt = partner.createdAt;
            }
            editThisPartner();
        }
    };

    const editThisPartner = (): void => {
        editPartner(partnerForm)
            .unwrap()
            .then(() => {
                dispatch(
                    setNotification({
                        content: `Successfully edited ${partnerForm.name}.`,
                        notificationType: notificationTypeEnum.EDITPARTNER,
                        eventType: 'success',
                    })
                );
                navigate(-1);
            })
            .catch((error) => {
                console.error(error);
                setSubmitLoading(false);
                dispatch(
                    setNotification({
                        content: `Failed to edit ${partnerForm.name}.`,
                        notificationType: notificationTypeEnum.EDITPARTNER,
                        eventType: 'error',
                    })
                );
                window.scrollTo(0, 0);
            });
    };

    const createNewPartner = (): void => {
        addPartner(partnerForm)
            .unwrap()
            .then(() => {
                resetState();
                setSubmitLoading(false);
                dispatch(
                    setNotification({
                        content: `Successfully added ${partnerForm.name}.`,
                        notificationType: notificationTypeEnum.ADDPARTNER,
                        eventType: 'success',
                    })
                );
                window.scrollTo(0, 0);
            })
            .catch((error) => {
                console.log(error);
                setSubmitLoading(false);
                dispatch(
                    setNotification({
                        content: `Failed to add ${partnerForm.name}.`,
                        notificationType: notificationTypeEnum.ADDPARTNER,
                        eventType: 'error',
                    })
                );
                window.scrollTo(0, 0);
            });
    };
    const formValidation = (): boolean => {
        let formErrors = false;
        if (!name) {
            setNameError('Name is required');
            formErrors = true;
        }
        if (!discoveryLink) {
            setLinkError('Discovery Link is required');
            formErrors = true;
        }
        return formErrors;
    };

    const stringStateFunction = [
        setName,
        setDiscoveryLink,
        setNameError,

        setLinkError,
        setSegmentError,
        setVerticalError,
        setWorkloadsError,
        setPartnerProgramsError,
        setSubSegmentsError,
        setGeosError,
    ];

    const arrayStateFunction = [
        setSegments,
        setVerticals,
        setWorkloads,
        setPrograms,
        setSubSegments,
        setGeos,
    ];
    const cancelEvent = (event) => {
        event.preventDefault();
        resetState();
        if (editMode) {
            navigate(-1);
        } else {
            window.scrollTo(0, 0);
        }
    };
    const resetState = () => {
        stringStateFunction.forEach((func) => func(''));
        arrayStateFunction.forEach((func) => func([]));
    };

    const isLoading =
        addPartnerResult.isLoading ||
        submitLoading ||
        editPartnerResult.isLoading;

    return (
        <div>
            <form onSubmit={onSubmit}>
                <Form
                    header={
                        <Header variant="h1">
                            {editMode ? 'Edit Partner' : 'Add New Partner'}
                        </Header>
                    }
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                formAction="none"
                                variant="link"
                                onClick={cancelEvent}
                            >
                                Cancel
                            </Button>
                            <Button variant="primary" loading={isLoading}>
                                Submit
                            </Button>
                        </SpaceBetween>
                    }
                >
                    <SpaceBetween size="l">
                        <PartnerDetailsForm {...partnerDetailsFormProps} />
                        <OptionalPartnerDetailsForm
                            {...additionalPartnerDetailsFormProps}
                        />
                    </SpaceBetween>
                </Form>
            </form>
        </div>
    );
};

export default PartnerForm;
