import { Box, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React from 'react';
import { PartnerDefinition } from '../../../interfaces/partner.interface';
import { useNavigate } from 'react-router-dom';

interface SolutionPartnerDetailsProps {
    partner: PartnerDefinition;
}

const SolutionPartnerDetails: React.FC<SolutionPartnerDetailsProps> = ({
    partner,
}) => {
    const navigate = useNavigate();
    const toPartner = (event) => {
        event.preventDefault();
        navigate(`/partners/${partner.name}`);
    };
    return (
        <SpaceBetween size="l">
            <div>
                <Box variant="awsui-key-label">Partner Name</Box>
                <Link onFollow={toPartner}>{partner.name}</Link>
            </div>
            <div>
                <Box variant="awsui-key-label">Discovery Portal Link</Box>
                <Link href={partner.discoveryLink} target="_blank">
                    {partner.name}
                </Link>
            </div>
        </SpaceBetween>
    );
};

export default SolutionPartnerDetails;
