import React from 'react';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import { SolutionDefinition } from '../../../interfaces/solution.interface';
import { mapDetails } from '../../common/DetailsList';

interface SolutionInfoProps {
    solution: SolutionDefinition;
}

const SolutionInfo: React.FC<SolutionInfoProps> = ({ solution }) => {
    return (
        <SpaceBetween size="l">
            <div>
                <Box variant="awsui-key-label">Solution Area</Box>
                <div>{solution.domain}</div>
            </div>
            {solution.segments && (
                <div>
                    <Box variant="awsui-key-label">Solution Segments</Box>
                    <div>{mapDetails(solution.segments || [])}</div>
                </div>
            )}
            {solution.industries && solution.industries.length > 0 && (
                <div>
                    <Box variant="awsui-key-label">Solution Industries</Box>
                    <div>{mapDetails(solution.industries)}</div>
                </div>
            )}
            {solution.subIndustries && solution.subIndustries.length > 0 && (
                <div>
                    <Box variant="awsui-key-label">Solution Sub Industries</Box>
                    <div>{mapDetails(solution.subIndustries)}</div>
                </div>
            )}
        </SpaceBetween>
    );
};

export default SolutionInfo;
