import React, { useEffect, useState } from 'react';
import {
    Button,
    Cards,
    Header,
    Input,
    Link,
    Pagination,
    PaginationProps,
    Select,
} from '@amzn/awsui-components-react';

import { useCollection } from '@amzn/awsui-collection-hooks';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { checkIfAdmin } from '../../../util/AuthService';
import {
    CUSTOMER_INDUSTRY,
    CUSTOMER_SEGMENT,
    GEO,
    INDUSTRY_MAPPING,
    S360_LINKS,
    SOLUTION_DOMAINS,
} from '../../../constants/Constants';
import './styles.css';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { TableEmptyState, TableNoMatchState } from '../../common/table';
import {
    useFetchAllPartnersQuery,
    useFetchAllSolutionsQuery,
} from '../../../store';
import {
    alphabetizeOptions,
    mapFilterToOption,
    mapOptionToFilter,
} from '../../../util/MapOptions';
import { SolutionDefinition } from '../../../interfaces/solution.interface';

const defaultPartnerOptions: OptionDefinition = {
    value: '',
    label: 'Any Partner',
};
const defaultDomainOptions: OptionDefinition = { value: '', label: 'Any Area' };
const defaultGeoOptions: OptionDefinition = { value: '', label: 'Any Geo' };
const defaultIndustryOptions: OptionDefinition = {
    value: '',
    label: 'Any Industry',
};
const defaultSubIndustryOptions: OptionDefinition = {
    value: '',
    label: 'Any Sub Industry',
};
const defaultSegmentOptions: OptionDefinition = {
    value: '',
    label: 'Any Segment',
};

const matchesPartner = (
    item: SolutionDefinition,
    selectedPartner: OptionDefinition | null
) => {
    if (!selectedPartner) {
        return true;
    }
    return (
        selectedPartner === defaultPartnerOptions ||
        item.partner === selectedPartner.label
    );
};

const matchesDomain = (
    item: SolutionDefinition,
    selectedDomain: OptionDefinition | null
) => {
    if (!selectedDomain) {
        return true;
    }
    return (
        selectedDomain === defaultDomainOptions ||
        item.domain === selectedDomain.label
    );
};

const matchesS360 = (item: SolutionDefinition, selectedS360: any) => {
    if (!selectedS360) {
        return true;
    }
    let s360Included = false;
    if (item.s360 && selectedS360) {
        const s360Label = S360_LINKS.find((item) => item.value === selectedS360)
            ?.label;
        if (s360Label) {
            s360Included = item.s360.includes(s360Label);
        }
    }

    return s360Included;
};

const matchesSubIndustry = (
    item: SolutionDefinition,
    selectedSubIndustry: OptionDefinition | null
) => {
    if (!selectedSubIndustry) {
        return true;
    }
    let subIndustryIncluded = false;
    if (item.subIndustries && selectedSubIndustry.label) {
        subIndustryIncluded = item.subIndustries.includes(
            selectedSubIndustry.label
        );
    }
    return (
        selectedSubIndustry === defaultSubIndustryOptions || subIndustryIncluded
    );
};

const SEARCHABLE_FIELDS = ['description', 'title'];

const Solutions: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { data: solutions, isLoading } = useFetchAllSolutionsQuery();
    const { data: partners, isLoading: isLoadingPartners } =
        useFetchAllPartnersQuery();

    const [isAdmin, setIsAdmin] = useState(false);
    // const [domainOption, setDomainOption] = useState<OptionDefinition>(defaultDomainOptions);
    // const [partnerOption, setPartnerOption] = useState<OptionDefinition>(defaultPartnerOptions);
    const [partnerOptions, setPartnerOptions] = useState<OptionDefinition[]>(
        []
    );
    // const [subIndustrySelection, setSubIndustrySelection] = useState<OptionDefinition>(defaultSubIndustryOptions)
    const [subIndustryOptions, setSubIndustryOptions] = useState<
        OptionDefinition[]
    >([]);

    const [searchParams] = useSearchParams();
    const geoValue = searchParams.get('geo');
    const industryValue = searchParams.get('industry');
    const subIndustryValue = searchParams.get('subIndustry');
    const domainValue = searchParams.get('area');
    const partnerValue = searchParams.get('partner');
    const segmentValue = searchParams.get('segment');
    const s360TriggerValue = searchParams.get('s360');
    const handleFilterChange = (option: OptionDefinition, param: string) => {
        const newFilter = mapOptionToFilter(option);
        if (newFilter) {
            searchParams.set(param, newFilter);
            navigate(`${location.pathname}?${searchParams.toString()}`);
        } else {
            searchParams.delete(param);
            if (param === 'industry') {
                searchParams.delete('subIndustry');
            }
            navigate(`${location.pathname}?${searchParams.toString()}`);
        }
    };

    const {
        items,
        actions,
        filteredItemsCount,
        collectionProps,
        filterProps,
        paginationProps,
    } = useCollection(solutions || [], {
        filtering: {
            empty: <TableEmptyState resourceName="Partner Solution" />,
            noMatch: <TableNoMatchState onClearFilter={() => clearFilters()} />,
            filteringFunction: (item: any, filteringText: string) => {
                if (
                    !matchesDomain(
                        item,
                        mapFilterToOption(domainValue, SOLUTION_DOMAINS)
                    )
                ) {
                    return false;
                }
                if (
                    !matchesPartner(
                        item,
                        mapFilterToOption(partnerValue, partnerOptions)
                    )
                ) {
                    return false;
                }
                if (
                    !matchesSubIndustry(
                        item,
                        mapFilterToOption(subIndustryValue, subIndustryOptions)
                    )
                ) {
                    return false;
                }
                if (!matchesS360(item, s360TriggerValue)) {
                    return false;
                }
                const filterTextLowerCase = filteringText.toLowerCase();
                return SEARCHABLE_FIELDS.map((key) => item[key]).some(
                    (value) =>
                        typeof value === 'string' &&
                        value.toLowerCase().indexOf(filterTextLowerCase) > -1
                );
            },
        },
        pagination: { pageSize: 30 },
        selection: {},
    });

    const clearFilters = () => {
        actions.setFiltering('');
        searchParams.forEach((_, key) => {
            searchParams.delete(key);
        });
        navigate(`${location.pathname}?${searchParams.toString()}`);
    };

    const openDetailsPage = (event: any, item: any) => {
        event.preventDefault();
        const path = `/solution/${item.id}`;
        navigate(path);
    };

    useEffect(() => {
        setIsAdmin(checkIfAdmin());
    }, []);

    useEffect(() => {
        if (partners) {
            let partnerList: OptionDefinition[] = [];
            partners.forEach((partner) => {
                partnerList.push({ label: partner.name, value: partner.name });
            });
            const partnerOptions = alphabetizeOptions(partnerList);
            setPartnerOptions(partnerOptions);
        }
    }, [partners]);

    // useEffect(() => {
    //     if (s360TriggerValue) {
    //         console.log(`S360 Trigger Value: ${s360TriggerValue}`);
    //         const s360Map = S360_LINKS.find(
    //             (element) => element.value === s360TriggerValue
    //         );
    //         if (s360Map) {
    //             setS360({ label: s360Map.label, value: s360Map.value });
    //         }
    //     }
    // }, [s360TriggerValue]);

    useEffect(() => {
        let options: OptionDefinition[] = [];
        const segmentOptions = mapFilterToOption(
            industryValue,
            CUSTOMER_INDUSTRY
        );
        const industryMap = INDUSTRY_MAPPING.find(
            (element) => segmentOptions?.value === element.industry
        );
        if (industryMap) {
            industryMap.subIndustry.forEach((subIndustry) => {
                options.push(subIndustry);
            });
        }
        setSubIndustryOptions(options);
    }, [industryValue]);

    return (
        <div>
            <Cards
                loading={isLoading}
                loadingText="Loading resources"
                {...collectionProps}
                header={<CardHeader isAdmin={isAdmin} />}
                filter={
                    <div>
                        <div className="input-container">
                            <div className="input-filter">
                                <Input
                                    type="search"
                                    placeholder="Find Solutions"
                                    value={filterProps.filteringText}
                                    onChange={(e) =>
                                        actions.setFiltering(e.detail.value)
                                    }
                                    disabled={isLoading}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="input-filter">
                                <Select
                                    data-testid="select-geo"
                                    selectedOption={mapFilterToOption(
                                        geoValue,
                                        [defaultGeoOptions, ...GEO]
                                    )}
                                    selectedAriaLabel="Selected"
                                    options={[defaultGeoOptions, ...GEO]}
                                    onChange={(e) => {
                                        handleFilterChange(
                                            e.detail.selectedOption,
                                            'geo'
                                        );
                                    }}
                                    expandToViewport={true}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="input-filter">
                                <Select
                                    data-testid="select-segment"
                                    selectedOption={mapFilterToOption(
                                        industryValue,
                                        [
                                            defaultIndustryOptions,
                                            ...CUSTOMER_INDUSTRY,
                                        ]
                                    )}
                                    selectedAriaLabel="Selected"
                                    options={[
                                        defaultIndustryOptions,
                                        ...CUSTOMER_INDUSTRY,
                                    ]}
                                    onChange={(e) => {
                                        handleFilterChange(
                                            e.detail.selectedOption,
                                            'industry'
                                        );
                                    }}
                                    expandToViewport={true}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="input-filter">
                                <Select
                                    data-test-id="select-subindusties"
                                    selectedOption={mapFilterToOption(
                                        subIndustryValue,
                                        [
                                            defaultSubIndustryOptions,
                                            ...subIndustryOptions,
                                        ]
                                    )}
                                    selectedAriaLabel="Selected"
                                    options={[
                                        defaultSubIndustryOptions,
                                        ...subIndustryOptions,
                                    ]}
                                    onChange={(e) => {
                                        handleFilterChange(
                                            e.detail.selectedOption,
                                            'subIndustry'
                                        );
                                    }}
                                    expandToViewport={true}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="input-filter">
                                <Select
                                    data-test-id="select-segment"
                                    selectedOption={mapFilterToOption(
                                        segmentValue,
                                        [
                                            defaultSegmentOptions,
                                            ...CUSTOMER_SEGMENT,
                                        ]
                                    )}
                                    selectedAriaLabel="Selected"
                                    options={[
                                        defaultSegmentOptions,
                                        ...CUSTOMER_SEGMENT,
                                    ]}
                                    onChange={(e) => {
                                        handleFilterChange(
                                            e.detail.selectedOption,
                                            'segment'
                                        );
                                    }}
                                    expandToViewport={true}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="select-filter">
                                <Select
                                    data-testid="select-domain"
                                    selectedOption={mapFilterToOption(
                                        domainValue,
                                        [
                                            defaultDomainOptions,
                                            ...SOLUTION_DOMAINS,
                                        ]
                                    )}
                                    selectedAriaLabel="Selected"
                                    options={[
                                        defaultDomainOptions,
                                        ...SOLUTION_DOMAINS,
                                    ]}
                                    onChange={(e) => {
                                        handleFilterChange(
                                            e.detail.selectedOption,
                                            'area'
                                        );
                                    }}
                                    expandToViewport={true}
                                    disabled={isLoading}
                                />
                            </div>
                            <div className="select-filter">
                                <Select
                                    filteringType="auto"
                                    statusType={
                                        isLoadingPartners
                                            ? 'pending'
                                            : 'finished'
                                    }
                                    data-testid="select-domain"
                                    selectedOption={mapFilterToOption(
                                        partnerValue,
                                        [
                                            defaultPartnerOptions,
                                            ...partnerOptions,
                                        ]
                                    )}
                                    selectedAriaLabel="Selected"
                                    options={[
                                        defaultPartnerOptions,
                                        ...partnerOptions,
                                    ]}
                                    onChange={(e) => {
                                        handleFilterChange(
                                            e.detail.selectedOption,
                                            'partner'
                                        );
                                    }}
                                    expandToViewport={true}
                                    disabled={isLoading}
                                />
                            </div>

                            <div aria-live="polite">
                                {(filterProps.filteringText ||
                                    domainValue ||
                                    partnerValue) && (
                                    <span className="filtering-results">
                                        {getTextFilterCounterText(
                                            filteredItemsCount
                                        )}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                }
                pagination={
                    <Pagination
                        {...paginationProps}
                        ariaLabels={paginationAriaLabels(
                            paginationProps.pagesCount
                        )}
                        disabled={isLoading}
                    />
                }
                stickyHeader={true}
                cardDefinition={{
                    header: (item: any) => (
                        <div>
                            <Link
                                fontSize="heading-m"
                                onFollow={(e) => openDetailsPage(e, item)}
                            >
                                {item.title}
                            </Link>
                        </div>
                    ),
                    sections: [
                        {
                            id: 'partner',
                            header: 'Partner',
                            content: (item) => item.partner,
                        },
                        {
                            id: 'domain',
                            header: 'Solution Area',
                            content: (item) => item.domain,
                        },
                    ],
                }}
                items={items}
            />
        </div>
    );
};

interface CardHeader {
    isAdmin: boolean;
}

const CardHeader: React.FC<CardHeader> = (props) => {
    const navigate = useNavigate();
    const { isAdmin } = props;
    const addSolutionButton = isAdmin ? (
        <Button variant="primary" onClick={(e) => addSolution(e)}>
            Add solution
        </Button>
    ) : null;
    const addSolution = (e) => {
        e.preventDefault();
        navigate('/solution/upload');
    };
    return (
        <div>
            <Header variant="awsui-h1-sticky" actions={addSolutionButton}>
                Partner Solutions
            </Header>
        </div>
    );
};

const getTextFilterCounterText = (count: number | undefined) =>
    `${count} ${count === 1 ? 'match' : 'matches'}`;

const paginationAriaLabels: (totalPages?: number) => PaginationProps.Labels = (
    totalPages
) => ({
    nextPageLabel: 'Next page',
    previousPageLabel: 'Previous page',
    pageLabel: (pageNumber) =>
        `Page ${pageNumber} of ${totalPages || 'all pages'}`,
});

export default Solutions;
