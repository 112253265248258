import React from 'react';
import {
    Box,
    ColumnLayout,
    Link,
    SpaceBetween,
} from '@amzn/awsui-components-react';
import { PartnerDefinition } from '../../../interfaces/partner.interface';

interface PartnerDetailsProps {
    partner: PartnerDefinition;
}

const PartnerCommonDetails: React.FC<PartnerDetailsProps> = ({ partner }) => {
    return (
        <ColumnLayout columns={2} variant="text-grid">
            <SpaceBetween size="l" direction="vertical">
                <div>
                    <Box variant="awsui-key-label">Partner Name</Box>
                    <div>{partner.name}</div>
                </div>
            </SpaceBetween>
            <SpaceBetween size="l" direction="vertical">
                <div>
                    <Box variant="awsui-key-label">Discovery Portal Link</Box>
                    <div>
                        <Link href={partner.discoveryLink} target="_bank">
                            {partner.name}
                        </Link>
                    </div>
                </div>
            </SpaceBetween>
        </ColumnLayout>
    );
};

export default PartnerCommonDetails;
