import React from 'react';
import {
    Container,
    FormField,
    Header,
    Input,
    SpaceBetween,
} from '@amzn/awsui-components-react';

export interface PartnerDetailsFormProps {
    name: string;
    setName: (name: string) => void;
    discoveryLink: string;
    setDiscoveryLink: (link: string) => void;
    nameError: string;
    setNameError: (nameError: string) => void;
    linkError: string;
    setLinkError: (linkError: string) => void;
    editMode: boolean;
}

const PartnerDetailsForm: React.FC<PartnerDetailsFormProps> = (props) => {
    const onNameChange = (event): void => {
        props.setName(event.detail.value);
        props.setNameError('');
    };

    const onLinkChange = (event) => {
        props.setDiscoveryLink(event.detail.value);
        props.setLinkError('');
    };

    return (
        <Container header={<Header variant="h2">Partner Details</Header>}>
            <SpaceBetween direction="vertical" size="l">
                <FormField label="Partner Name" errorText={props.nameError}>
                    <Input
                        value={props.name}
                        onChange={onNameChange}
                        disabled={props.editMode}
                    />
                </FormField>
                <FormField
                    label="Partner Discovery Portal Link"
                    errorText={props.linkError}
                >
                    <Input
                        value={props.discoveryLink}
                        onChange={onLinkChange}
                    />
                </FormField>
            </SpaceBetween>
        </Container>
    );
};

export default PartnerDetailsForm;
